import React, { useState } from 'react';

function ToggleButton({value, onChange, disabled, id}) {
    const [toggleValue, setToggleValue] = useState(value);

    function handleCheckedValue(e) {
        setToggleValue(e.target.checked);
        onChange(e.target.checked);
    }

    return (
        <div>
            <label className="switch">
                <input id={id} checked={toggleValue} type="checkbox" disabled={disabled} onChange={handleCheckedValue}/>
                <span className="slider"></span>
            </label>
        </div>
    );
}

export default ToggleButton;