import { Box, Divider, FormLayout, Text, TextField, BlockStack } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMemberPanelData } from '../../../redux/slice/DataSlice';
import Input from '../../../common-components/Input';

function Member({ formik }) {
    const panel_homepage_member = formik.values?.panel_homepage_member;
    const [memberPanelData, setMemberPanelData] = useState(panel_homepage_member);
    const dispatch = useDispatch();

    useEffect(() => {
        setMemberPanelData(formik.values);
    }, [formik.values?.panel_homepage_member]);

    useEffect(() => {
        dispatch(updateMemberPanelData(memberPanelData));
    }, [memberPanelData]);

    return (
        <>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <Text as="h3" variant="headingMd" fontWeight='medium' >
                        Header text
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Welcome to our store'
                        type='text'
                        value={formik.values?.panel_homepage_member?.header_text_title.trimStart()}
                        name='panel_homepage_member.header_text_title'
                        handleChange={formik.setFieldValue}
                    />
                </BlockStack>
            </Box>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <Text as="h3" variant="headingMd" fontWeight='medium' >
                        Activity banner
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Hello {firstname}'
                        type='text'
                        value={formik.values?.panel_homepage_member?.activity_banner_title.trimStart()}
                        name='panel_homepage_member.activity_banner_title'
                        handleChange={formik.setFieldValue}
                    />
                    <Input
                        label='Social Activity'
                        placeholder='Complete social activities'
                        type='text'
                        value={formik.values?.panel_homepage_member?.social_activity.trimStart()}
                        name='panel_homepage_member.social_activity'
                        handleChange={formik.setFieldValue}
                    />
                </BlockStack>
            </Box>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <Text as="h3" variant="headingMd" fontWeight='medium' >
                        Birthday
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Birthday'
                        type='text'
                        value={formik.values?.panel_homepage_member?.birthday_title.trimStart()}
                        name='panel_homepage_member.birthday_title'
                        handleChange={formik.setFieldValue}
                    />
                    <TextField
                        label='Description'
                        placeholder='We want to celebrate your birthday!'
                        autoComplete="off"
                        multiline={4}
                        type='text'
                        value={formik.values?.panel_homepage_member?.birthday_desc.trimStart()}
                        onChange={(e) => formik.setFieldValue('panel_homepage_member.birthday_desc', e)}
                    />
                </BlockStack>
            </Box>
        </>
    );
}

export default Member;