import React, { useEffect, useState } from 'react';
import { Box, Divider, InlineStack, Text, Tooltip, BlockStack, InlineError } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import { updateColorBrandingData } from '../../../redux/slice/DataSlice';
import DeleteModal from '../../../common-components/pop_up_modal/DeleteModal';
import Input from '../../../common-components/Input';

const Colors = ({ formik }) => {
    const theme_colors = formik.values?.theme_colors;
    const [defaultColorSelected] = useState(false);
    const [themeColorData, setThemeColorData] = useState(theme_colors);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const primary_color = '#332105';
    const secondary_color = '#EBE9E6';
    const hexColorPattern = /^#[0-9A-Fa-f]{6}$/;
    const defaultColorData = {
        banner_color: primary_color,
        banner_font: secondary_color,
        primary_buttons_color: primary_color,
        primary_buttons_font: secondary_color,
        default_buttons_border_color: primary_color,
        default_buttons_font: primary_color,
        links_color: primary_color,
        background_color: secondary_color
    };

    function handleThemeSelection(e) {
        const name = e.target.name;
        const value = e.target.value;
        formik.setFieldValue(name, value);
    }
    useEffect(() => {
        setThemeColorData(formik.values?.theme_colors);
    }, [formik.values?.theme_colors]);

    useEffect(() => {
        dispatch(updateColorBrandingData(themeColorData));
    }, [themeColorData]);

    // const checkColorIsDefaultSelected = () => {
    //     const arrayFlag = Object.entries(defaultColorData).map(([key, colorValue]) => {
    //         return formik_launcher && formik_launcher[key] === colorValue;
    //     });

    //     const setSelectedDefault = arrayFlag.every(Boolean);
    //     setDefaultColorSelect(setSelectedDefault);
    // };

    // useEffect(() => {
    //     checkColorIsDefaultSelected();
    // }, [formik_launcher]);

    const resetAllColor = () => {
        for (const key in defaultColorData) {
            if (Object.hasOwnProperty.call(defaultColorData, key)) {
                const colorValue = defaultColorData[key];
                formik.setFieldValue(`theme_colors.${key}`, colorValue);
            }
        }
        // checkColorIsDefaultSelected();
        setLoading(false);
        setVisible(false);
    };

   
    // console.log(formik.values?.theme_colors?.banner_color.trimStart());

    return (
        <>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <InlineStack align='space-between' blockAlign='center'>
                        <Box>
                            <Text as="h3" variant="bodyLg" fontWeight='medium'>
                                Theme color
                            </Text>
                        </Box>
                        <Box>
                            <Tooltip content='Revert to default'>
                                <div className='color-picker theme-color-default' onClick={() => setVisible(!visible)} style={{ borderColor: defaultColorSelected ? '#2C6ECB' : '' }}>
                                    <div style={{ backgroundColor: primary_color }} className='btn-revert-color'></div>
                                    <div style={{ backgroundColor: secondary_color }} className='btn-revert-color'></div>
                                    {/* <Box><input type='color' name='theme_primary_color' value={primary_color} readOnly/></Box> */}
                                    {/* <Box><input type='color' name='theme_secondary_color' value={secondary_color} readOnly/></Box> */}
                                </div>
                            </Tooltip>
                            <DeleteModal
                                deletePopUpActive={visible}
                                handleDeletePopUp={() => setVisible(false)}
                                popUpContent='Are you sure to reset it with default colors?'
                                primaryAction={{ content: 'Revert', loading: loading === 'revert', onAction: () => { setLoading('revert'); setTimeout(() => resetAllColor(), 500); }, destructive: true }}
                                secondaryActions={[{ content: 'Cancel', onAction: () => setVisible(false) }]}
                                popUpTitle={'Revert to default?'}
                            />
                        </Box>
                    </InlineStack>
                    <Text as="h3" variant="bodyLg" fontWeight='medium'>
                        Banner
                    </Text>
                    <InlineStack wrap={false} gap={300}>
                        <div>
                            <Input
                                label='Color'
                                type='value'
                                value={(formik.values?.theme_colors?.banner_color.trimStart())}
                                name='theme_colors.banner_color'
                                handleChange={formik.setFieldValue}
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.theme_colors?.banner_color}
                                touched={formik.touched?.theme_colors?.banner_color}
                                maxLength={7}
                                connectedRight={
                                    <Box><input type='color' name='theme_colors.banner_color' value={formik.values?.theme_colors?.banner_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                            {hexColorPattern.test(formik.values?.theme_colors?.banner_color) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}

                        </div>

                        <div>
                            <Input
                                label='Font'
                                type='value'
                                value={(formik.values?.theme_colors?.banner_font.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='theme_colors.banner_font'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.theme_colors?.banner_font}
                                touched={formik.touched?.theme_colors?.banner_font}
                                maxLength={7}
                                connectedRight={
                                    <Box><input type='color' name='theme_colors.banner_font' value={formik.values?.theme_colors?.banner_font} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                            {hexColorPattern.test(formik.values?.theme_colors?.banner_font) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}


                        </div>

                    </InlineStack>
                    <Divider />
                    <BlockStack gap={200}>
                        <Text as="h3" variant="bodyLg" fontWeight='medium'>
                        Primary buttons
                        </Text>
                        <InlineStack wrap={false} gap={300}>
                            <Box>
                                <Input
                                    label='Color'
                                    type='value'
                                    value={(formik.values?.theme_colors?.primary_buttons_color.trimStart())}
                                    handleChange={formik.setFieldValue}
                                    name='theme_colors.primary_buttons_color'
                                    handleBlur={formik.setFieldTouched}
                                    error={formik.errors?.theme_colors?.primary_buttons_color}
                                    touched={formik.touched?.theme_colors?.primary_buttons_color}
                                    maxLength={7}
                                    connectedRight={
                                        <Box><input type='color' name='theme_colors.primary_buttons_color' value={formik.values?.theme_colors?.primary_buttons_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                    }
                                />
                                {hexColorPattern.test(formik.values?.theme_colors?.primary_buttons_color) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}

                            </Box>
                            <Box>
                                <Input
                                    label='Font'
                                    type='value'
                                    value={(formik.values?.theme_colors?.primary_buttons_font.trimStart())}
                                    handleChange={formik.setFieldValue}
                                    name='theme_colors.primary_buttons_font'
                                    handleBlur={formik.setFieldTouched}
                                    error={formik.errors?.theme_colors?.primary_buttons_font}
                                    touched={formik.touched?.theme_colors?.primary_buttons_font}
                                    maxLength={7}
                                    connectedRight={
                                        <Box><input type='color' name='theme_colors.primary_buttons_font' value={formik.values?.theme_colors?.primary_buttons_font} onChange={(e) => handleThemeSelection(e)} /></Box>
                                    }
                                />
                                {hexColorPattern.test(formik.values?.theme_colors?.primary_buttons_font) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}
                            </Box>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap={200}>
                        <Text as="h3" variant="bodyLg" fontWeight='medium'>
                        Default buttons
                        </Text>
                        <InlineStack wrap={false} gap={300}>
                            <Box>
                                <Input
                                    label='Border color'
                                    type='value'
                                    value={(formik.values?.theme_colors?.default_buttons_border_color.trimStart())}
                                    handleChange={formik.setFieldValue}
                                    name='theme_colors.default_buttons_border_color'
                                    handleBlur={formik.setFieldTouched}
                                    error={formik.errors?.theme_colors?.default_buttons_border_color}
                                    touched={formik.touched?.theme_colors?.default_buttons_border_color}
                                    maxLength={7}
                                    connectedRight={
                                        <Box><input type='color' name='theme_colors.default_buttons_border_color' value={formik.values?.theme_colors?.default_buttons_border_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                    }
                                />
                                {hexColorPattern.test(formik.values?.theme_colors?.default_buttons_border_color) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}
                            </Box>
                            <Box>
                                <Input
                                    label='Font'
                                    type='value'
                                    value={(formik.values?.theme_colors?.default_buttons_font.trimStart())}
                                    handleChange={formik.setFieldValue}
                                    name='theme_colors.default_buttons_font'
                                    handleBlur={formik.setFieldTouched}
                                    error={formik.errors?.theme_colors?.default_buttons_font}
                                    touched={formik.touched?.theme_colors?.default_buttons_font}
                                    maxLength={7}
                                    connectedRight={
                                        <Box><input type='color' name='theme_colors.default_buttons_font' value={formik.values?.theme_colors?.default_buttons_font} onChange={(e) => handleThemeSelection(e)} /></Box>
                                    }
                                />
                                {hexColorPattern.test(formik.values?.theme_colors?.default_buttons_font) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}
                            </Box>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap={200}>
                        <Text as="h3" variant="bodyLg" fontWeight='medium'>
                        Links
                        </Text>

                        <Box>
                            <Input
                                label='Color'
                                type='value'
                                value={(formik.values?.theme_colors?.links_color.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='theme_colors.links_color'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.theme_colors?.links_color}
                                touched={formik.touched?.theme_colors?.links_color}
                                maxLength={7}
                                connectedRight={
                                    <Box><input type='color' name='theme_colors.links_color' value={formik.values?.theme_colors?.links_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                            {hexColorPattern.test(formik.values?.theme_colors?.links_color) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}
                        </Box>

                    </BlockStack>
                    <Divider />
                    <BlockStack gap={200}>
                        <Text as="h3" variant="bodyLg" fontWeight='medium'>
                        Background
                        </Text>

                        <Box>
                            <Input
                                label='Color'
                                type='value'
                                value={(formik.values?.theme_colors?.background_color.trimStart())}
                                handleChange={formik.setFieldValue}
                                name='theme_colors.background_color'
                                handleBlur={formik.setFieldTouched}
                                error={formik.errors?.theme_colors?.background_color}
                                touched={formik.touched?.theme_colors?.background_color}
                                autoComplete="off"
                                maxLength={7}
                                connectedRight={
                                    <Box><input type='color' name='theme_colors.background_color' value={formik.values?.theme_colors?.background_color} onChange={(e) => handleThemeSelection(e)} /></Box>
                                }
                            />
                            {hexColorPattern.test(formik.values?.theme_colors?.background_color) ? '' : <InlineError message="Enter valid color" fieldID="myFieldID" />}
                        </Box>

                    </BlockStack>
                </BlockStack>
            </Box>
        </>
    );
};

export default Colors;