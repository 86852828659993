import { Box, InlineStack, Layout, Card, SkeletonBodyText, SkeletonDisplayText, BlockStack } from '@shopify/polaris';
import React from 'react';

function SkeletonProgram() {
    return (
        <Layout sectioned>
            <BlockStack gap={200}>
                <Box padding={200}></Box>
                <Card sectioned>
                    <BlockStack  gap={200}>
                        <SkeletonDisplayText size='small' />
                        <SkeletonBodyText lines={1.5}/>
                    </BlockStack>
                </Card>
                <Card sectioned>
                    <BlockStack  gap={200}>
                        <SkeletonDisplayText size='small' />
                        <SkeletonBodyText />
                    </BlockStack>
                </Card>
                <Card sectioned>
                    <BlockStack gap={200}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText />
                    </BlockStack>
                </Card>
            </BlockStack>
        </Layout>
    );
}

export default SkeletonProgram;