/* eslint-disable react/react-in-jsx-scope */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config_variable } from '../../helper/config';
import CommonApiEndPoint from '../../helper/commonApiEndPoint';

export const generateToken = createAsyncThunk('fetch/generateToken', async () => {
    const res = await axios.post(config_variable.domain + CommonApiEndPoint.generate_token, { shop: config_variable.shop_name });
    return res?.data?.data;
});
const dataSlice = createSlice({
    name: 'GenerateTokenData',
    initialState: {
        store_data: {},
        status: false,
        program_page: 0,
        referral_tab: 0,
        point_tab: 0,
        app_status: '',
        app_block_status: '',
        tour_step: '',
        tour_status: '',
        color_branding_data: {},
        placement_branding_data: {},
        visibility_branding_data: {},
        member_panel: {},
        visitor_panel: {},
        desktop_launch: {},
        mobile_launch: {},
        panel_id: false,
        launcher_id: false,
        theme_id: 'colors',
        customerFilters: {
            member_status: [],
            pageCount: 1,
            handlesortBy: 'updated',
            handleorderBy: 'desc',
            searchValue: '',
            range: { start: '', end: '' },
            exact: '',
            tab: 0
        },
        PointFilter: {
            point_status: ''
        },
        redeemFilter : {
            redeem_status : []
        },
        syncBannerStatus: false,
        launcher_icon_name: '',
        Earn_icon_name: '',
        redeem_icon_name: '',
        launcher_general_icon_custom: false,
        earn_points_icon_custom: false,
        redeem_points_icon: false
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateToken.pending, (state) => {
                state.status = false;
            })
            .addCase(generateToken.fulfilled, (state, { payload }) => {
                const { shop_info, token } = payload;
                if (process.env?.MODE !== 'local' && window.name !== 'app-iframe') {
                    window.parent.location.href = `https://${window.shopify.config.shop}/admin/apps/${window.shopify.config.apiKey}`;
                } else {
                    state.store_data = { shop_data: shop_info, token };
                    state.app_status = shop_info?.app_status;
                    state.app_block_status = shop_info?.app_block_status;
                    state.tour_step = shop_info?.tour_step;
                    state.status = true;
                }
            })
            .addCase(generateToken.rejected, (state) => {
                state.isError = true;
                window.parent.location.href = `${config_variable.domain}?shop=${config_variable.shop_name}&host=${config_variable?.config?.host}`;
            });
    },
    reducers: {
        updateProgramPage: (state, action) => {
            state.program_page = action.payload;
        },
        updateReferralTab: (state, action) => {
            state.referral_tab = action.payload;
        },
        updatePointsTab: (state, action) => {
            state.point_tab = action.payload;
        },
        updateOnboardingStep: (state, action) => {
            state.store_data.onboarding_steps = action.payload;
        },
        updateAppStatus: (state, action) => {
            state.app_status = action.payload;
        },
        updateAppBlockStatus: (state, action) => {
            state.app_block_status = action.payload;
        },
        updateTourStep: (state, action) => {
            state.tour_step = action.payload;
        },
        updateTourStatus: (state, action) => {
            state.tour_status = action.payload;
        },
        updateColorBrandingData: (state, action) => {
            state.color_branding_data = action.payload;
        },
        updatePlacementBrandingData: (state, action) => {
            state.placement_branding_data = action.payload;
        },
        updateVisibilityData: (state, action) => {
            state.visibility_branding_data = action.payload;
        },
        updateMemberPanelData: (state, action) => {
            state.member_panel = action.payload;
        },
        updateVisitorPanelData: (state, action) => {
            state.visitor_panel = action.payload;
        },
        updateDesktopLaunchData: (state, action) => {
            state.desktop_launch = action.payload;
        },
        updateMobileLaunchData: (state, action) => {
            state.mobile_launch = action.payload;
        },
        handlePanelId: (state, action) => {
            state.panel_id = action.payload;
        },
        handleLauncherId: (state, action) => {
            state.launcher_id = action.payload;
        },
        handleThemeId: (state, action) => {
            state.theme_id = action.payload;
        },
        updateCustomerFilters: (state, action) => {
            state.customerFilters = action.payload;
        },
        setSyncBannerStatus: (state, action) => {
            state.syncBannerStatus = action.payload;
        },
        updateIconname: (state, action) => {
            state.launcher_icon_name = action.payload;
        },
        updateEarniconname: (state, action) => {
            state.Earn_icon_name = action.payload;
        },
        updateRedeemiconname: (state, action) => {
            state.redeem_icon_name = action.payload;
        },
        updatePointsTabFilter: (state, action) => {
            state.PointFilter.point_status = action.payload;
        },
        updateRedeemTabFilter: (state, action) => {
            state.redeemFilter.redeem_status = action.payload;
        },
        updatelauncher_icone: (state, action) => {
            state.launcher_general_icon_custom = action.payload;
        },
        updateearn_points_icone: (state, action) => {
            state.earn_points_icon_custom = action.payload;
        },
        updateredeem_points_icone: (state, action) => {
            state.redeem_points_icon = action.payload;
        }
    }
});

export const { updateProgramPage, updateReferralTab, updatePointsTab, updateAppStatus, updateAppBlockStatus, updateColorBrandingData, updatePlacementBrandingData, updateVisibilityData, updateMemberPanelData, updateVisitorPanelData, updateDesktopLaunchData, updateMobileLaunchData, handlePanelId, handleLauncherId, handleThemeId, updateTourStep, updateTourStatus, updateOnboardingStep, updateCustomerFilters, setSyncBannerStatus, updateIconname, updateEarniconname, updateRedeemiconname, updatePointsTabFilter, updateRedeemTabFilter, updatelauncher_icone, updateearn_points_icone, updateredeem_points_icone } = dataSlice.actions;
export default dataSlice.reducer;