import React, { useState } from 'react';
import Colors from './Colors';
import ColorIcon from '../../../assets/images/color.svg';
import VisibilityIcon from '../../../assets/images/visibility.svg';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg';
import ArrowUpIcon from '../../../assets/images/arrow-up.svg';
import { Box, Collapsible, Divider, InlineStack, LegacyStack, Text } from '@shopify/polaris';
import Visibility from './Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { handleThemeId } from '../../../redux/slice/DataSlice';

const Theme = ({ formik }) => {
    const dispatch = useDispatch();
    const theme_id = useSelector(state => state?.data?.theme_id);
    const [themeID, setThemeID] = useState(theme_id);

    function handleToggle(id = false) {
        try {
            if (id && themeID !== id) {
                setThemeID(id);
                dispatch(handleThemeId(id));
            } else {
                setThemeID(false);
                dispatch(handleThemeId(false));
            }
        } catch (error) {
            console.log('error------------------', error);
        }
    }

    return (
        <>

            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleToggle('colors')}
                    aria-expanded={themeID === 'colors'}
                    aria-controls="basic-collapsible">
                    <InlineStack align='space-between' blockAlign='center'>
                        <Box>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={ColorIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Colors
                                </Text>
                            </div>
                        </Box>
                        <Box>
                            {themeID === 'colors' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </Box>
                    </InlineStack>
                </button>
            </Box>
            <Collapsible
                open={themeID === 'colors'}
                id="basic-collapsible"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Colors formik={formik} />
            </Collapsible>
            <Divider />
            <Box padding={300} >
                <button
                    className={'collapse-button-container'}
                    onClick={() => handleToggle('visibility')}
                    aria-expanded={themeID === 'visibility'}
                    aria-controls="basic-collapsible">
                    <InlineStack  align='space-between' blockAlign='center'>
                        <Box>
                            <div className='branding-toogle-container'>
                                <div className='branding-color-title'>
                                    <img
                                        src={VisibilityIcon}
                                        alt="color icon"
                                    />
                                </div>
                                <Text as="h3" variant="headingMd" fontWeight='medium'>
                                    Visibility
                                </Text>
                            </div>
                        </Box>
                        <Box>
                            {themeID === 'visibility' ? <img src={ArrowUpIcon} alt="arrow down icon" /> : <img src={ArrowDownIcon} alt="arrow down icon" />}
                        </Box>
                    </InlineStack>
                </button>
            </Box>
            <Collapsible
                open={themeID === 'visibility'}
                id="basic-collapsible3"
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                <Visibility formik={formik}  />
            </Collapsible>
        </>
    );
};

export default Theme;