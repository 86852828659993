import React, { useEffect, useState } from 'react';
import { Box, Divider, FormLayout, InlineError, Text, TextField, BlockStack } from '@shopify/polaris';
import DropZoneFile from '../../../common-components/DropZoneFile';
import { useDispatch, useSelector } from 'react-redux';
import { updateVisitorPanelData, updateEarniconname, updateRedeemiconname } from '../../../redux/slice/DataSlice';
import Input from '../../../common-components/Input';

function CommonSetting({ formik, brandingData }) {
    const Earn_points_icon_name = useSelector(state => state?.data?.Earn_icon_name);
    const Redeem_points_icon_name = useSelector(state => state?.data?.redeem_icon_name);
    // console.log(Earn_points_icon_name);
    // console.log(Redeem_points_icon_name);
    const panel_program_card_settings = formik.values?.panel_program_card_settings;
    const [visitorPanelData, setVisitorPanelData] = useState(panel_program_card_settings);
    const [customFile, setCustomFile] = useState(brandingData?.panel_program_card_settings?.earn_points_icon_type ? brandingData?.panel_program_card_settings?.earn_points_icon_custom ? brandingData?.panel_program_card_settings?.earn_points_icon_custom : formik.values?.panel_program_card_settings?.earn_points_icon_custom ? formik.values?.panel_program_card_settings?.earn_points_icon_custom : '' : '');
    // console.log("customFile---->", customFile);
    const [custome, setcustome] = useState(Earn_points_icon_name);
    const [customFileredeem, setCustomFileredeem] = useState(brandingData?.panel_program_card_settings?.redeem_points_icon_type ? brandingData?.panel_program_card_settings?.redeem_points_icon_custom ? brandingData?.panel_program_card_settings?.redeem_points_icon_custom : formik.values?.panel_program_card_settings?.redeem_points_icon_custom ? formik.values?.panel_program_card_settings?.redeem_points_icon_custom : '' : '');
    const dispatch = useDispatch();
    const [customeFileredeemname, setcustomeFileredeemname] = useState(Redeem_points_icon_name);
    // console.log(custome);
    // console.log(formik.values?.panel_program_card_settings?.earn_points_icon_custom);
    useEffect(() => {
        setVisitorPanelData(formik.values?.panel_program_card_settings);
    }, [formik.values?.panel_program_card_settings]);

    useEffect(() => {
        if (formik?.values?.panel_program_card_settings?.earn_points_icon_type) {
            // console.log('111');
            formik.setFieldValue('panel_program_card_settings.earn_points_icon_type', 1);
            setCustomFile(formik.values?.panel_program_card_settings?.earn_points_icon_custom ? formik.values?.panel_program_card_settings?.earn_points_icon_custom : formik.values?.panel_program_card_settings?.earn_points_icon);
        } else {
            // console.log('222');
            setCustomFile('');
        }
    }, [formik.values?.panel_program_card_settings?.earn_points_icon_custom]);

    useEffect(() => {
        if (formik?.values?.panel_program_card_settings?.redeem_points_icon_type) {
            // console.log('333');
            formik.setFieldValue('panel_program_card_settings.redeem_points_icon_type', 1);
            setCustomFileredeem(formik.values?.panel_program_card_settings?.redeem_points_icon_custom ? formik.values?.panel_program_card_settings?.redeem_points_icon_custom : formik.values?.panel_program_card_settings?.redeem_points_icon);
        } else {
            // console.log('444');
            setCustomFileredeem('');
        }
    }, [formik.values?.panel_program_card_settings?.redeem_points_icon_custom]);

    useEffect(() => {
        if (brandingData?.panel_program_card_settings?.earn_points_icon_type) {
            // console.log('555');
            formik.setFieldValue('panel_program_card_settings.earn_points_icon_custom', formik.values?.panel_program_card_settings?.earn_points_icon_custom ? formik.values?.panel_program_card_settings?.earn_points_icon_custom : brandingData?.panel_program_card_settings?.earn_points_icon);
            if (!formik.dirty) {
                // console.log('666');
                setCustomFile(brandingData?.panel_program_card_settings?.earn_points_icon);
            }
        } else {
            // console.log('777');
            // setCustomFile('');
        }

        if (brandingData?.panel_program_card_settings?.redeem_points_icon_type) {
            // console.log('888');
            formik.setFieldValue('panel_program_card_settings.redeem_points_icon_custom', formik.values?.panel_program_card_settings?.redeem_points_icon_custom ? formik.values?.panel_program_card_settings?.redeem_points_icon_custom : brandingData?.panel_program_card_settings?.redeem_points_icon);
            if (!formik.dirty) {
                // console.log('999');
                setCustomFileredeem(brandingData?.panel_program_card_settings?.redeem_points_icon);
            }
        } else {
            // console.log('101');
            // setCustomFileredeem('');
        }
    }, [brandingData?.panel_program_card_settings, formik.dirty]);
    useEffect(() => {
        if (!formik.dirty) {
            dispatch(updateEarniconname(''));
            dispatch(updateRedeemiconname(''));
            setcustome('');
            setcustomeFileredeemname('');
        }
    }, [formik.dirty]);
    useEffect(() => {
        dispatch(updateVisitorPanelData(visitorPanelData));
    }, [visitorPanelData]);
    const handkeIconename = (value, point_icon_status) => {
        // console.log("value--->", value.name);
        // console.log("status--->", point_icon_status);
        if (point_icon_status) {
            // console.log("102");
            dispatch(updateEarniconname(value.name));
            // setcustome(value.name);
        } else {
            // console.log("103");
            dispatch(updateRedeemiconname(value.name));
            // setcustomeFileredeemname(value.name);
        }
    };

    const handleImageUploadStatus = (value, point_icon_status) => {
        // console.log(value);
        const iconType = point_icon_status ? 'panel_program_card_settings.earn_points_icon_type' : 'panel_program_card_settings.redeem_points_icon_type';
        if (value) {
            // console.log('104');
            formik.setFieldValue(iconType, 1);
            formik.setFieldValue(point_icon_status ? 'panel_program_card_settings.earn_points_icon_custom' : 'panel_program_card_settings.redeem_points_icon_custom', value);
            if (point_icon_status) {
                // console.log('105');
                // console.log(value);
                setCustomFile(value);
            } else {
                // console.log('105');
                setCustomFileredeem(value);
            }

        } else {
            // console.log('106');
            formik.setFieldValue(iconType, 0);
            formik.setFieldValue(point_icon_status ? 'panel_program_card_settings.earn_points_icon_custom' : 'panel_program_card_settings.redeem_points_icon_custom', undefined);
            if (point_icon_status) {
                // console.log('107');
                setCustomFile('');
            } else {
                // console.log('107');
                setCustomFileredeem('');
            }
        }
    };

    // console.log(formik.values?.panel_program_card_settings?.earn_points_icon_custom.includes('data:image'));
    // console.log(formik.values?.panel_program_card_settings?.redeem_points_icon);
    // console.log(formik.values?.panel_program_card_settings?.redeem_points_icon_custom.includes('data:image'));
    return (
        <>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <Text as='h2' variant='headingMd' fontWeight='medium'>Program-card setting</Text>
                    <Text as="h3" variant="headingMd" fontWeight='medium'>
                        Points
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Loyalti Program'
                        type='text'
                        value={formik.values?.panel_program_card_settings?.points_title.trimStart()}
                        name='panel_program_card_settings.points_title'
                        handleChange={formik.setFieldValue}
                    />
                    <TextField
                        label='Description'
                        placeholder='Accrue {points} from a variety of actions and trade them for appealing rewards.'
                        autoComplete="off"
                        multiline={4}
                        type='text'
                        value={formik.values?.panel_program_card_settings?.points_desc}
                        name='points_desc'
                        onChange={(e) => formik.setFieldValue('panel_program_card_settings.points_desc', e)}
                    />

                    <DropZoneFile
                        fileLabel={'Earn points icon'}
                        fileAccept={'.png, .jpg, .jpeg'}
                        fileActionTitle={'Add icon'}
                        fileActionHint={'Size 90px by 90px'}
                        fileValue={formik.values?.panel_program_card_settings?.earn_points_icon_value}
                        handleImportValue={(value) => handleImageUploadStatus(value, 1)}
                        customFileVal={customFile}
                        handleicone={(value) => handkeIconename(value, 1)}
                        custome={custome}

                    />


                    {formik.errors?.panel_program_card_settings?.earn_points_icon_custom && <InlineError message={formik.errors?.panel_program_card_settings?.earn_points_icon_custom} />}


                    <DropZoneFile
                        fileLabel={'Redeem points icon'}
                        fileAccept={'.png, .jpg, .jpeg'}
                        fileActionTitle={'Add icon'}
                        fileActionHint={'Size 90px by 90px'}
                        fileValue={formik.values?.panel_program_card_settings?.redeem_points_icon_value}
                        handleImportValue={(value) => handleImageUploadStatus(value, 0)}
                        customFileVal={customFileredeem}
                        handleicone={(value) => handkeIconename(value, 0)}
                        redeempointcustome={customeFileredeemname}
                    />

                    {formik.errors?.panel_program_card_settings?.redeem_points_icon_custom && <InlineError message={formik.errors?.panel_program_card_settings?.redeem_points_icon_custom} />}
                    {/* <DropZoneFile  fileLabel={'Referrals icon'} fileAccept={'.svg, .png'} fileActionTitle={'Add icon'} fileActionHint={'Size 90px by 90px'} handleImportValue={(value) => formik.setFieldValue('panel_referrals_details', value)} /> */}
                    {/* {customFile ? <div>
                                    {uploadedFile}
                                </div> : <Box paddingBlockStart={100}><DropZone allowMultiple={false} onDrop={handleUpload} type='file' accept='.csv'>
                                    <DropZone.FileUpload actionTitle='Add icon' actionHint='or drop files to upload'/>
                                </DropZone></Box>} */}
                </BlockStack>
            </Box>
        </>
    );
}

export default CommonSetting;