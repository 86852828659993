import { Box, Divider, Text, TextField, BlockStack } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateVisitorPanelData } from '../../../redux/slice/DataSlice';
import Input from '../../../common-components/Input';

const Visitor = ({ formik }) => {
    const panel_homepage_visitor = formik.values?.panel_homepage_visitor;
    const [visitorPanelData, setVisitorPanelData] = useState(panel_homepage_visitor);
    const dispatch = useDispatch();

    useEffect(() => {
        setVisitorPanelData(formik.values?.panel_homepage_visitor);
    }, [formik.values?.panel_homepage_visitor]);

    useEffect(() => {
        dispatch(updateVisitorPanelData(visitorPanelData));
    }, [visitorPanelData]);

  

    return (
        <>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <Text as="h3" variant="headingMd" fontWeight='medium'>
                        Header text
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Welcome to our store'
                        type='text'
                        value={formik.values?.panel_homepage_visitor?.header_text_title.trimStart()}
                        name='panel_homepage_visitor.header_text_title'
                        handleChange={formik.setFieldValue}
                    />
                </BlockStack>
            </Box>
            <Divider />
            <Box padding={300} >
                <BlockStack gap={200}>
                    <Text as="h3" variant="headingMd" fontWeight='medium'>
                        Account creation text
                    </Text>
                    <Input
                        label='Title'
                        placeholder='Membership Card'
                        type='text'
                        value={formik.values?.panel_homepage_visitor?.account_creation_title.trimStart()}
                        name='panel_homepage_visitor.account_creation_title'
                        handleChange={formik.setFieldValue}
                    />
                    <TextField
                        label='Description'
                        placeholder='Explore range of opportunities to unlock enticing perks, ensuring access to exclusive rewards.'
                        autoComplete="off"
                        multiline={4}
                        type='text'
                        value={formik.values?.panel_homepage_visitor?.account_creation_desc.trimStart()}
                        onChange={(e) => formik.setFieldValue('panel_homepage_visitor.account_creation_desc', e)}
                    />
                    <Input
                        label='Button'
                        placeholder='Join now'
                        type='text'
                        value={formik.values?.panel_homepage_visitor?.account_creation_button.trimStart()}
                        name='panel_homepage_visitor.account_creation_button'
                        handleChange={formik.setFieldValue}
                    />
                    <Input
                        label='Sign in'
                        placeholder='Already have an account?'
                        type='text'
                        value={formik.values?.panel_homepage_visitor?.account_creation_signin.trimStart()}
                        name='panel_homepage_visitor.account_creation_signin'
                        handleChange={formik.setFieldValue}
                    />
                </BlockStack>
            </Box>
        </>
    );
};

export default React.memo(Visitor);