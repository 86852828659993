/* eslint-disable multiline-ternary */
import { Box, Button, Divider, HorizontalStack, LegacyCard, Page, Frame, Text, Toast, VerticalStack, TextField, Tag, RadioButton, LegacyStack, Banner, HorizontalGrid, Link, Badge, ChoiceList, Popover, ButtonGroup, Icon, Tooltip, Label, InlineError, IndexTable, Pagination, Spinner, EmptySearchResult, Scrollable } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { config_variable } from '../../helper/config';
import { ApiCall, GetApiCall } from '../../helper/axios';
import CommonApiEndPoint from '../../helper/commonApiEndPoint';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { ContextualSaveBar, Provider, TitleBar } from '@shopify/app-bridge-react';
import Savebar from '../../common-components/Savebar';
import SkeletonProgram from '../../common-components/SkeletonProgram';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppBlockStatus, updateAppStatus, updateTourStatus, updateTourStep } from '../../redux/slice/DataSlice';
import SingleCheckbox from '../../common-components/Checkbox';
import { EditMajor, HideMinor, PlusMinor, ViewMajor, CircleInformationMajor } from '@shopify/polaris-icons';
import ActionModal from '../../common-components/pop_up_modal/ActionModal';
import EnableLoyalty from '../../assets/images/EnableLoyalty.png';
import DisableLoyalty from '../../assets/images/DisableLoyalty.png';
import Footer from '../../common-components/Footer';
import AppBridgeSaveBar from '../../common-components/AppBridgeSaveBar';
import { setTitleBar } from '../../redux/slice/appBridgeSlice';
import Input from '../../common-components/Input';
import Selection from '../../common-components/Selection';

function Point() {
    const dispatch = useDispatch();
    const rowsPerPage = 10;
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const [tagValue, setTagValue] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [visible, setVisible] = useState(false);
    const shop_info = useSelector((state) => state.data.store_data?.shop_data);
    const currency = shop_info?.store_currency_sign;
    const app_status = useSelector((state) => state.data?.app_status);
    const app_block_status = useSelector((state) => state.data?.app_block_status);
    const tour_step = useSelector((state) => state.data?.tour_step);
    const [statusvalue, setstatusvalue] = useState((app_status === '0') ? '0' : '1');
    const [appblockStatus, setappblockStatus] = useState();
    const [tourStepValue, settourStepValue] = useState(tour_step);
    const [status, setstatus] = useState((app_status === '1') ? 'Disable' : 'Enable');
    const [validateApi, setvalidateApi] = useState(true);
    const [callapi, setcallapi] = useState(false);
    const [rewardableTotal, setRewardableTotal] = useState(100.00);
    const [popUpActive, setPopUpActive] = useState(false);
    const [popupId, setPopupID] = useState(false);
    const [selectedCreditCurrency, setSelectedCreditCurrency] = useState([]);
    const [selectedOldCreditCurrency, setSelectedOldCreditCurrency] = useState([]);
    const [disabledAddBtn, setDisabledAddBtn] = useState(false);
    const [storeCreditCurrencyList, setStoreCreditCurrencyList] = useState({});
    const [storeCreditCurrencyOption, setStoreCreditCurrencyOption] = useState([]);
    const [selectedAddCurrencyList, setSelectedAddCurrencyList] = useState([]);
    const [isDisableAddCurrencyBtn, setIsDisableAddCurrencyBtn] = useState(false);
    const [storeCreditDetail, setStoreCreditDetail] = useState([]);
    const [disabledStoreCreditDetail, setDisabledStoreCreditDetail] = useState([]);
    const [page, setPage] = useState(1);
    const [Disabledpage, setDisabledPage] = useState(1);
    const [totalEnabledCount, setTotalEnabledCount] = useState(0);
    const [totalDisabledCount, setTotalDisabledCount] = useState(0);
    let CountOfSelectedDisableCurrency = 0;

    const [data, setData] = useState({
        enable_guest_enrollment: 1,
        rewardable_sales_channels: 'online',
        award_points_when_order_marked_as: 'Fulfilled',
        order_total_reward_value_include_taxes: 0,
        order_total_reward_value_include_shipping: 0,
        order_total_reward_value_include_discounts: 0,
        notification_email: '',
        notification_name: '',
        notification_reply_email: '',
        excluded_customer_tags: [],
        store_credit_currencies: [],
        store_credit_default_currency: '',
        store_credit_default_currency_conversion_value: null,
        store_credit_min_bal: 0,
        store_credit_min_bal_status: 0,
        store_credit_status: 0,
        disabled: []
    });
    /* iw0081 - form validation */
    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema: yup.object({
            notification_email: yup.string().email('Invalid email').required('Required'),
            notification_reply_email: yup.string().email('Invalid email').required('Required'),
            store_credit_min_bal_status: yup.number(),
            store_credit_min_bal: yup.number().when('store_credit_min_bal_status', {
                is: value => value === 1,
                then: schema => schema.required('Store credit minimum balance must be a required').min(1, 'Enter non-zero whole value as points')
            }),
            store_credit_scope: yup.number().required('Store credit scope is required'),
            store_credit_default_currency_conversion_value: yup.number()
                .when('store_credit_scope', {
                    is: 1,
                    then: schema => schema
                        .required('Store conversion-transaction must be required')
                        .min(1, 'Enter a non-zero whole value as points'),
                    otherwise: schema => schema
                })
        }),
        onSubmit: (values) => {
            setLoading('submit');
            handleSubmitSettings(values);
        }
    });

    /* iw0081 - Retrive old saved data */
    async function getSettingsData() {
        try {
            const res = await GetApiCall('POST', CommonApiEndPoint.getSettingsData);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                resData['store_credit_min_bal'] = resData['store_credit_min_bal'] ? resData['store_credit_min_bal'] : resData['store_credit_min_bal_status'] ? null : '';
                if (resData['store_credit_scope'] !== 1) {
                    resData['store_credit_default_currency_conversion_value'] = resData['store_credit_default_currency_conversion_value'] ? resData['store_credit_default_currency_conversion_value'] : 0;
                }
                setData({ ...data, ...resData });
                setSelectedTags(resData?.excluded_customer_tags);
                if (resData?.store_credit_currencies.length) {
                    setStoreCreditDetail(resData?.store_credit_currencies);
                    setDisabledStoreCreditDetail(resData?.disabled);
                    const total = resData?.storeCreditCurrenciesTotal - Number(resData?.disabled?.length || 0);
                    setTotalEnabledCount(Math.ceil(total / rowsPerPage));
                    setTotalDisabledCount(Math.ceil(resData?.countOfDisabledCurrencies / rowsPerPage));
                    if (resData?.countOfDisabledCurrencies) {
                        CountOfSelectedDisableCurrency = resData?.countOfDisabledCurrencies;
                    }
                }

                // resData?.store_credit_currencies.length ? getStoreCreditList() : '';
            }
            setLoading(false);
        } catch (error) {
            console.log('error--------------------', error);
        }
    }
    
    function removeLeadingZerosRegex(str) {
        if (str) {
            return str.toString().replace(/^0+(?=\d)/, '');
        }
    }

    const verifyAppblock = async () => {
        setLoading('update_app_status');
        const appblockres = await ApiCall('POST', CommonApiEndPoint.validateStatus);
        if (appblockres.status === 200) {
            if (appblockres?.data?.data?.app_status) {
                setstatusvalue(appblockres.data.data.app_status);
                dispatch(updateAppStatus(appblockres.data.data.app_status));
                setstatus((appblockres.data.data.app_status === '0') ? 'Enable' : 'Disable');
            }
            if (appblockres.data.data.app_block_status) {
                setappblockStatus(appblockres.data.data.app_block_status);
                if (appblockres.data.data.app_block_status === '2' && app_block_status === '2') {
                    dispatch(updateAppBlockStatus('3'));
                }
            }
            settourStepValue(appblockres.data.data.tour_step);
            dispatch(updateTourStep(appblockres.data.data.tour_step));
            dispatch(updateTourStatus(appblockres.data.data.tour_status));
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const AppStatus = async (tour_status, tour_step, app_status) => {
        setLoading('update_app_status');
        const payloadData = {
            ...tour_step !== '' && { tour_step: `${tour_step}` },
            ...tour_status !== '' && { tour_status: `${tour_status}` },
            ...app_status !== '' && { app_status: `${app_status}` }
        };
        const res = await ApiCall('POST', CommonApiEndPoint.updateAppStatus, payloadData);
        if (res?.data?.status === 'SUCCESS') {
            if (app_status !== '') {
                setstatusvalue(app_status);
                setstatus((app_status === '0') ? 'Enable' : 'Disable');
                setToastMessage(res.data.message);
                dispatch(updateAppStatus(`${app_status}`));
                toggleActive();
                setVisible(false);
                setvalidateApi(false);
            }
            if (tour_step !== '') {
                settourStepValue(tour_step);
                dispatch(updateTourStep(`${tour_step}`));
            }
            setLoading(false);
            if (tour_status === '') {
                window.open(res.data.data.url);
                if (visible) setVisible(false);
            }
        }
        if (res.status !== 200) {
            setLoading(false);
            setToastMessage(res.data.message);
            toggleActive();
        }
    };

    const getappBlockStatus = async () => {
        const res = await ApiCall('POST', CommonApiEndPoint.getAppBlockStatus);
        if (res?.data?.status === 'SUCCESS') {
            const resData = res?.data?.data;
            setappblockStatus(resData.app_block_status);
            dispatch(updateAppBlockStatus(resData.app_block_status));
        }
    };

    const getStoreCreditList = async (pageNo) => {
        try {
            setPage(pageNo);
            const data = {
                page: pageNo,
                limit: rowsPerPage
            };
            const res = await ApiCall('POST', `${CommonApiEndPoint.getStoreCreditDetail}`, data);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                setStoreCreditDetail(resData?.nonPrimaryCurrencies);
                const total = resData?.totalData - Number(CountOfSelectedDisableCurrency);
                setTotalEnabledCount(Math.ceil(total / rowsPerPage));
            }
        } catch (error) {
            console.log('error----->', error);
        }
    };

    const getDisabledStoreCreditList = async (pageNo) => {
        try {
            setDisabledPage(pageNo);
            const res = await ApiCall('GET', `${CommonApiEndPoint.getDisabledStoreCurrency}?page=${pageNo}&limit=${rowsPerPage}`);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                setDisabledStoreCreditDetail(resData?.selectDisableCurrency);
                const total = resData?.totalData;
                CountOfSelectedDisableCurrency = total;
                setTotalDisabledCount(Math.ceil(total / rowsPerPage));
            }
        } catch (error) {
            console.log('error----->', error);
        }
    };

    useEffect(() => {
        setLoading('page_load');
        setTimeout(() => getSettingsData(), 500);
        dispatch(setTitleBar({
            title: 'Settings'
        }));
        if (app_status === '1' && tour_step === '1' && validateApi) {
            verifyAppblock();
        } else {
            if (app_block_status && !callapi) {
                getappBlockStatus();
                setcallapi(true);
            }
        }
    }, []);

    const removeTag = (tag) => {
        const previousTags = [...selectedTags];
        const filteredTags = previousTags.filter((item) => item !== tag);
        setSelectedTags([...filteredTags]);
        formik.setFieldValue('excluded_customer_tags', filteredTags);
    };

    const handleAddTags = () => {
        const tags = [...selectedTags];
        if (tagValue?.length) {
            tags.push(tagValue);
            setSelectedTags([...tags]);
            formik.setFieldValue('excluded_customer_tags', [...tags]);
            setTagValue('');
        }
    };

    const tagMarkup = selectedTags.map((option) => (<Tag key={option} onRemove={() => removeTag(option)}>{option}</Tag>));

    /* iw0081 - form submit event */
    async function handleSubmitSettings(values) {
        delete values['id'];
        delete values['store_client_id'];
        delete values['store_credit_default_currency_code'];
        delete values['add_more_currency_status'];
        delete values['store_credit_currencies'];
        delete values['storeCreditCurrenciesTotal'];
        delete values['countOfDisabledCurrencies'];

        try {
            const payload = {
                ...values,
                app_block_status: undefined,
                disabled: undefined,
                store_credit_default_currency_conversion_value: values.store_credit_default_currency_conversion_value === '' ? null : values.store_credit_default_currency_conversion_value
            };
            const res = await ApiCall('POST', CommonApiEndPoint.saveSettingsData, payload);
            if (res?.data?.status === 'SUCCESS') {
                setData({
                    ...values,
                    add_more_currency_status: res?.data?.data?.add_more_currency_status
                });
                formik.setSubmitting(true);
                setToastMessage(res?.data?.message);
                toggleActive();
            } else {
                formik.setSubmitting(false);
                setToastMessage(res?.data?.message);
                toggleActive();
            }
            setTimeout(() => setLoading(false), 500);
        } catch (error) {
            console.log('error----------------->>', error);
        }
    }
    const resetForm = () => {
        formik.handleReset();
        setSelectedTags(data.excluded_customer_tags);
    };
    const handleSubmit = () => { formik.handleSubmit(); };
    const saveAction = {
        disabled: !formik.dirty || loading === 'submit',
        loading: loading === 'submit',
        onAction: handleSubmit
    };
    const discardAction = {
        disabled: !formik.dirty,
        loading: false,
        discardConfirmationModal: true,
        onAction: resetForm
    };

    useEffect(() => {
        const rewardableTotal = 100.00;
        const discount = formik?.values?.order_total_reward_value_include_discounts ? -10.00 : 0;
        const tax = formik.values?.order_total_reward_value_include_taxes ? 5.00 : 0;
        const shipping = formik.values?.order_total_reward_value_include_shipping ? 12.00 : 0;
        setRewardableTotal(rewardableTotal + discount + tax + shipping);
    }, [formik.values?.order_total_reward_value_include_shipping, formik.values?.order_total_reward_value_include_taxes, formik?.values?.order_total_reward_value_include_discounts]);

    const handlePopUpModal = async (flag = false, id = false) => {
        try {
            if (flag) {
                try {
                    const res = await GetApiCall('POST', CommonApiEndPoint.getStoreCurrencies);
                    if (res?.data?.status === 'SUCCESS') {
                        if (res?.data?.status === 'SUCCESS') {
                            const result = res?.data?.data;
                            setStoreCreditCurrencyList(result);

                            const currencyOptionData = result.map(({ currency }) => ({
                                label: currency,
                                value: currency
                            }));
                            setStoreCreditCurrencyOption(currencyOptionData);
                        }
                    }
                } catch (error) {
                    console.log('error------->', error);
                }
                setPopUpActive(id);
            } else {
                formik.handleReset();
                setPopUpActive(false);
                setSelectedCreditCurrency([]);
                setSelectedOldCreditCurrency([]);
                setSelectedAddCurrencyList([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function handlePopupActivation(id = false) {
        try {
            if (id && popupId !== id) {
                setPopupID(id);
            } else {
                setPopupID(false);
                // setInlineError('');
                formik.handleReset();
            }
        } catch (error) {
            console.log('error------------------', error);
        }
    }

    useEffect(() => {
        if (popupId !== 'currency_status') {
            const updatedStoreCreditCurrencyOptions = storeCreditCurrencyOption.reduce((acc, item) => {
                if (selectedCreditCurrency.includes(item.label)) {
                    acc.selected.push(item);
                } else {
                    acc.unselected.push(item);
                }
                return acc;
            }, { selected: [], unselected: [] });
            setStoreCreditCurrencyOption([...updatedStoreCreditCurrencyOptions.selected, ...updatedStoreCreditCurrencyOptions.unselected]);
        }
    }, [popupId]);

    useEffect(() => {
        const selectedCreditCurrencyList = Object.keys(storeCreditCurrencyList).length ? storeCreditCurrencyList?.filter(item => selectedCreditCurrency.includes(item.currency)) : [];
        setSelectedAddCurrencyList(selectedCreditCurrencyList);
    }, [selectedCreditCurrency]);

    const handleAddCurrencyConversion = async () => {
        try {
            const payloadData = selectedAddCurrencyList.map(({ id, conversion_value }) => ({
                sc_id: id,
                conversion_value: Number(conversion_value)
            }));
            const res = await ApiCall('POST', CommonApiEndPoint.storeCreditAddCurrency, payloadData);
            if (res?.data?.status === 'SUCCESS') {
                setSelectedCreditCurrency([]);
                setSelectedOldCreditCurrency([]);
                setSelectedAddCurrencyList([]);
                handlePopUpModal();
                setToastMessage(res?.data?.message);
                toggleActive();

                const resData = res?.data?.data;
                setStoreCreditDetail(resData?.nonPrimaryCurrencies);
                const addMOreCurrencyStatus = resData?.addMoreCurrencyStatus;
                setData({ ...data, add_more_currency_status: addMOreCurrencyStatus });
                // getStoreCreditList();
            }
        } catch (error) {
            console.log('error------->', error);
        }
    };

    const handleChangeConversion = (index, val) => {
        const newChangeCurrency = [...selectedAddCurrencyList];
        newChangeCurrency[index].conversion_value = val;
        const checkBtnDisable = newChangeCurrency.every((item) => item.conversion_value > 0);

        setIsDisableAddCurrencyBtn(!checkBtnDisable);
        setSelectedAddCurrencyList(newChangeCurrency);
    };

    const emptyStateMarkup = (
        <EmptySearchResult
            title={'No orders found'}
            description={'Try changing the filters or search term'}
            withIllustration
        />
    );

    const RowMarkup = ({ item, index }) => {
        const [isEdit, setIsEdit] = useState(false);
        const [conversionVal, setConversionVal] = useState(item.conversion_value);
        const show = item?.status;

        const handleEditClick = () => {
            setIsEdit(true);
        };

        const handleUpdateConversionFun = (val) => {
            setConversionVal(val);
        };

        const updateConversionVal = async (index) => {
            try {
                const data = {
                    conversion_value: Number(conversionVal)
                };
                const res = await ApiCall('POST', `${CommonApiEndPoint.storeCreditCurrencyUpdate}/${item.id}`, data);
                if (res?.data?.status === 'SUCCESS') {
                    const newChangeStatus = [...storeCreditDetail];
                    newChangeStatus[index].conversion_value = conversionVal;
                    setStoreCreditDetail(newChangeStatus);
                    toggleActive();
                    setToastMessage(res.data.message);
                }
                setLoading(false);
            } catch (error) {
                console.log('error-------->', error);
            }
        };

        const handleBlurConversionFun = () => {
            if (conversionVal !== '' && conversionVal > 0) {
                if (conversionVal !== item.conversion_value) {
                    updateConversionVal(index);
                }
                setConversionVal(conversionVal);
            } else {
                setConversionVal(item?.conversion_value);
            }
            setIsEdit(false);
        };

        const updateStatusConversion = async () => {
            try {
                const viewStatus = !show ? 1 : 0;
                const data = {
                    status: viewStatus
                };
                // setShow(!show);
                const res = await ApiCall('POST', `${CommonApiEndPoint.scCurrencyStatusUpdate}/${item.id}`, data);

                if (res?.data?.status === 'SUCCESS') {
                    const newChangeStatus = [...storeCreditDetail];
                    newChangeStatus[index].status = viewStatus;
                    setStoreCreditDetail(newChangeStatus);
                    toggleActive();
                    setToastMessage(res.data.message);
                }
                setLoading(false);
            } catch (error) {
                console.log('error-------->', error);
            }
        };

        return (
            <>
                <IndexTable.Row
                    id={item.id}
                    key={index}
                >
                    <IndexTable.Cell>
                        <Text>{item.label}</Text>
                    </IndexTable.Cell>
                    <IndexTable.Cell className='program_act'>
                        <HorizontalStack>
                            {isEdit ? <><div style={{ opacity: isEdit ? '1' : '0.5', pointerEvents: isEdit ? '' : 'none' }}>
                                <TextField
                                    value={removeLeadingZerosRegex(Number(conversionVal))}
                                    type="number"
                                    min={1}
                                    maxLength={6}
                                    max={100000}
                                    borderless={true}
                                    focused={isEdit}
                                    onChange={(val) => (String(val)?.length <= 6 ? handleUpdateConversionFun(Number(val) >= 1 ? val : 0) : '')}
                                    onBlur={(val) => handleBlurConversionFun(val)}
                                // touched={conversionVal === '' ? 'required' : conversionVal > 0 ? '' : 'Plaese add proper value'}
                                // error={conversionVal === '' ? 'required' : conversionVal > 0 ? '' : 'Plaese add proper value'}
                                />
                            </div>
                            <div style={{ opacity: '1', pointerEvents: isEdit ? '' : 'none' }}>
                                <Text>Pts = 1 Store-credit</Text>
                            </div></> : <Text>{`${conversionVal} Pts = 1 Store-credit`}</Text>}
                        </HorizontalStack>
                        {conversionVal || conversionVal === '' ? <InlineError message={conversionVal === '' ? 'required' : conversionVal > 0 ? '' : 'Plaese add proper value'} /> : null}
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <div className='flex-end'>
                            <ButtonGroup segmented >
                                <Button size='micro' onClick={() => handleEditClick()}>
                                    <Icon source={EditMajor} />
                                </Button>
                                <Button size='micro' onClick={updateStatusConversion}>
                                    <Tooltip content={show ? 'Enable' : 'Disable'} >
                                        <Icon source={show ? ViewMajor : HideMinor} />
                                    </Tooltip>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </IndexTable.Cell>
                </IndexTable.Row>


            </>
        );
    };

    const AddMoreCurrencyPopUPContent = (
        <VerticalStack gap={'200'}>
            <div className='lnr-connected'>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className="Polaris-Label__Text Polaris-Text--medium">Currency</label>
                    </div>
                </div>
                {selectedCreditCurrency.length || popupId === 'currency_status' ? <><Box borderWidth='1' borderRadius='2' borderColor='border-subdued'>
                    <Box padding={300}>
                        <HorizontalStack align='space-between' blockAlign='center'>
                            <Text>{selectedCreditCurrency.length ? `${selectedCreditCurrency.length} Currency selected` : 'Select a currency'}</Text>
                            {!popupId && <Button size='slim' onClick={() => handlePopupActivation('currency_status')}>Edit</Button>}
                        </HorizontalStack>
                    </Box>
                    <Divider />
                    {popupId === 'currency_status' ? <div className='check-list'>
                        <div className='check-list-gap'>
                            <ChoiceList
                                choices={storeCreditCurrencyOption}
                                selected={selectedCreditCurrency}
                                onChange={(value) => { setSelectedCreditCurrency(value); setDisabledAddBtn(JSON.stringify(value) === JSON.stringify(selectedOldCreditCurrency)); }}
                                allowMultiple
                            />
                        </div>
                    </div> : null}
                </Box>
                </> : <Button
                    fullWidth={true}
                    textAlign='left'
                    disclosure='select'
                    onClick={() => handlePopupActivation('currency_status')}>{selectedCreditCurrency.length ? `${selectedCreditCurrency.length} Currency selected` : 'Select a currency'}</Button>}
            </div>
            <HorizontalStack gap={'200'}>
                {popupId !== 'currency_status' && selectedAddCurrencyList.length ?
                    <>
                        {/* <Scrollable style={{ maxHeight: '278px' }}> */}
                        {selectedAddCurrencyList.map((item, index) => {
                            return (
                            // eslint-disable-next-line react/jsx-key
                                <Box paddingBlockStart={400} key={index} width='100%'>
                                    <HorizontalStack gap={'200'}>
                                        <HorizontalGrid columns={2} gap={5}>
                                            <div style={{ pointerEvents: 'none' }} className='currency-money'>
                                                {!index ? <div className="Polaris-Labelled__LabelWrapper">
                                                    <div className="Polaris-Label">
                                                        <label className="Polaris-Label__Text Polaris-Text--medium">Currency</label>
                                                    </div>
                                                </div> : ''}
                                                <div style={{ opacity: '0.5' }}>
                                                    <TextField
                                                        value={item.currency}
                                                        type="text"
                                                        // borderless={true}
                                                        placeholder='Type-in (exact Shopify) tag value and click ->'
                                                        name={item.code}
                                                    // onChange={(value) => setTagValue(value)}
                                                    />
                                                </div>
                                            </div>
                                            <Input
                                                label={!index ? <Text fontWeight='medium'>Set conversion-transaction value</Text> : ''}
                                                value={removeLeadingZerosRegex(Number(item.conversion_value))}
                                                type="number"
                                                min={1}
                                                maxLength={6}
                                                max={100000}
                                                name={index}
                                                placeholder={'e.g. 100'}
                                                suffix={'Pts=1 Store credit'}
                                                handleChange={(name, val) => (String(val)?.length <= 6 ? handleChangeConversion(name, Number(val) >= 1 ? Number(val) : 0) : '')}
                                                // handleBlur={(name, val) => handleChangeConversion(name, val)}
                                                error={item.conversion_value === '' ? 'conversion value is required' : item.conversion_value < 1 ? 'Please add  a valid conversion value' : null}
                                                touched={item.conversion_value === '' ? 'conversion value is required' : item.conversion_value < 1 ? 'Please add  a valid conversion value' : null}
                                            />

                                        </HorizontalGrid>
                                    </HorizontalStack>
                                </Box>
                            );
                        })}
                        {/* </Scrollable> */}
                    </> : ''}

            </HorizontalStack>
        </VerticalStack>

    );

    const updateScopeStoreCredit = async () => {
        try {
            const res = await GetApiCall('POST', CommonApiEndPoint.storeCreditScopeUpdate);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                window.open(resData?.redirectUrl);
            }
            setLoading(false);
        } catch (error) {
            console.log('error------>', error);

        }
    };

    const handleRedirectSetting = () => {
        try {
            window.top.location = `https://admin.shopify.com/store/${config_variable.shop_name.split('.')[0]}/settings/customer_accounts`;
        } catch (error) {
            console.log('error---->', error);
        }
    };

    return (<Page>
        {loading === 'page_load' ? <SkeletonProgram /> : <Frame>
            {process.env?.MODE !== 'local' &&
                <AppBridgeSaveBar
                    isShowShopify={true}
                    saveAction={saveAction}
                    discardAction={discardAction}
                    fullWidth
                    leaveConfirmationDisable={true}
                    visible={formik.dirty}
                    loading={loading === 'submit'}
                />
                /* visible={formik.dirty || (formik.values.store_credit_scope === 1 && Number(formik.values.store_credit_default_currency_conversion_value) === 0)} */
            }
            {(((app_status === '1' && appblockStatus === '3') || (app_status === '0' && appblockStatus === '2')) && tour_step !== '1') ? <Box paddingBlockEnd={'400'}><Banner
                title={`
                            ${(app_status === '1' && appblockStatus === '3') ? 'Activate Loyalty and Rewards  app from Shopify theme editor.' : ''}
                            ${(app_status === '0' && appblockStatus === '2') ? 'Activate Loyalty and Rewards app ' : ''}                            
                            `}
                status="warning"
            >
                {(app_status === '1' && appblockStatus === '3') && 'We saw you Enabled the app, but the app\'s embed is still not activated for the live theme. '}
                {(app_status === '1' && appblockStatus === '3') &&
                    <><a target='_blank' href={`https://${config_variable.shop_name}/admin/themes/current/editor?context=apps&activateAppId=${config_variable.EXTENSION_UUID}/${config_variable.EXTENSION_BLOCK_NAME}`} rel="noreferrer" >Click here</a> to enable.</>}
                {(app_status === '0' && appblockStatus === '2') && 'We saw your app\'s embed is Enabled for the live theme but the app isn\'t active. Click below to enable.'}
            </Banner></Box> : <></>
            }
            <LegacyCard>
                {process.env?.MODE === 'local' && formik.dirty && <>
                    <AppBridgeSaveBar
                        savbtn={'Save'}
                        onClick={handleSubmit}
                        disabled={!formik.dirty || loading === 'submit'}
                        loader={loading === 'submit'}
                        discardClick={resetForm}
                        visible={formik.dirty}
                    />
                </>}
                <Box padding='400'>
                    <HorizontalStack align='space-between' blockAlign='center'>
                        <div>
                            <Box paddingBlockEnd={'025'}>
                                <Text as="h3" variant="headingMd">
                                    App status
                                </Text>
                            </Box>
                            <HorizontalStack gap={'050'} blockAlign='center'>
                                {(tourStepValue === '1' && statusvalue === '1') && <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 10.125C15.2984 10.125 15.5845 10.2435 15.7955 10.4545C16.0065 10.6655 16.125 10.9516 16.125 11.25V16.5C16.125 16.7984 16.0065 17.0845 15.7955 17.2955C15.5845 17.5065 15.2984 17.625 15 17.625C14.7016 17.625 14.4155 17.5065 14.2045 17.2955C13.9935 17.0845 13.875 16.7984 13.875 16.5V11.25C13.875 10.9516 13.9935 10.6655 14.2045 10.4545C14.4155 10.2435 14.7016 10.125 15 10.125Z" fill="#FFC453"></path>
                                    <path d="M16.5 20.25C16.5 20.6478 16.342 21.0294 16.0607 21.3107C15.7794 21.592 15.3978 21.75 15 21.75C14.6022 21.75 14.2206 21.592 13.9393 21.3107C13.658 21.0294 13.5 20.6478 13.5 20.25C13.5 19.8522 13.658 19.4706 13.9393 19.1893C14.2206 18.908 14.6022 18.75 15 18.75C15.3978 18.75 15.7794 18.908 16.0607 19.1893C16.342 19.4706 16.5 19.8522 16.5 20.25Z" fill="#FFC453"></path>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.0002 5.25C13.4327 5.25 12.3242 6.303 11.7722 7.4205C10.779 9.34883 9.77649 11.2724 8.76471 13.191L8.72271 13.269C7.70833 15.1916 6.70432 17.1196 5.71071 19.053C5.15271 20.181 4.99371 21.678 5.85021 22.974C6.70521 24.2685 8.16321 24.75 9.54621 24.75H20.4542C21.8372 24.75 23.2967 24.27 24.1502 22.974C25.0052 21.678 24.8477 20.181 24.2897 19.053C23.9747 18.417 22.6202 15.8325 21.2837 13.2825L21.2357 13.191C20.2235 11.2726 19.221 9.34905 18.2282 7.4205C17.6762 6.303 16.5677 5.25 15.0002 5.25ZM13.7882 8.418C14.3942 7.194 15.6062 7.194 16.2122 8.418C16.5152 9.0315 17.8802 11.6355 19.2452 14.238C20.2643 16.1702 21.2733 18.1078 22.2722 20.0505C22.8782 21.276 22.2722 22.5 20.4542 22.5H9.54621C7.72821 22.5 7.12071 21.276 7.72821 20.0505C8.03121 19.4385 9.39771 16.83 10.7627 14.226C11.7812 12.2952 12.7897 10.3592 13.7882 8.418Z" fill="#FFC453"></path>
                                </svg>}
                                <p>{statusvalue === '1' ? tourStepValue === '1' ? <p>{'Validate your app\'s activation status'}</p> : appblockStatus === '2' ? <p>Your app is now enabled</p> : <p>App is now Enabled</p> : appblockStatus === '2' ? <p>App embed is Enabled but your app is Disabled. Activate your app now</p> : <p>Enable your app now</p>}</p>
                            </HorizontalStack>
                        </div>
                        <div>
                            <Button
                                primary={statusvalue === '1' ? tourStepValue === '1' : true}
                                destructive={statusvalue === '1' ? tourStepValue !== '1' : false}
                                loading={!visible && loading === 'update_app_status'}
                                onClick={() => {
                                    if (statusvalue === '1') {
                                        if (tourStepValue === '1') {
                                            verifyAppblock();
                                        } else {
                                            setVisible(true);
                                        }
                                    } else {
                                        setVisible(true);
                                    }
                                    // (app_block_status === '1' ? updateAppStatus() : setVisible(!visible))}
                                }}
                            >
                                {statusvalue === '1' ? tourStepValue === '1' ? 'Validate now' : status : status}
                            </Button>
                            <ActionModal
                                popUpActive={visible}
                                handlePreviewPopUp={() => setVisible(false)}
                                popUpContent={
                                    <Text>
                                        <img
                                            alt=""
                                            width="100%"
                                            height="100%"
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: 'center'
                                            }}
                                            src={((statusvalue === '0') ? EnableLoyalty : DisableLoyalty)}
                                        />
                                    </Text>
                                }
                                popUpTitle={`Essential action: ${(statusvalue === '1') ? 'Disable' : 'Enable'} App block in your selected Shopify theme`}
                                secondaryActions={[
                                    {
                                        content: 'Go to Theme Editor',
                                        disabled: loading,
                                        loading,
                                        onAction: () => AppStatus('', (statusvalue === '0') ? '1' : '0', (statusvalue === '0') ? '1' : '0')
                                    }
                                ]}
                            />
                        </div>
                    </HorizontalStack>
                </Box>
            </LegacyCard>
            <LegacyCard>
                <Box padding='400'>
                    <Text as="h3" variant="headingMd">Program participants</Text>
                </Box>
                <Divider />
                <Box padding={400} >
                    <VerticalStack gap={200}>
                        <div>
                            <Text as="h3" variant='headingSm' >Manage who can participate in program</Text>
                            <Text as="p" color='subdued'>Decide whether guests can also participate</Text>
                        </div>
                        <SingleCheckbox
                            checked={formik.values.enable_guest_enrollment}
                            handleChange={(name, value) => formik.setFieldValue(name, value ? 1 : 0)}
                            label='Enable Guest/Visitor enrollment in the rewards program'
                            name='enable_guest_enrollment'
                            helpText="All customers, including guests, can earn points to access rewards mandates a store account."
                        />
                        <Divider />
                        <Text as="h3" variant='headingSm' >Limit program access</Text>
                        <div className='lnr-connected'>
                            <TextField
                                value={tagValue}
                                label="Exclude tagged-customers from Program benefits"
                                type="text"
                                borderless={true}
                                placeholder='Type-in (exact Shopify) tag value and click ->'
                                connectedRight={
                                    <Button icon={PlusMinor} fullWidth onClick={handleAddTags}>Add tag</Button>
                                }
                                name='excluded_customer_tags'
                                onChange={(value) => setTagValue(value)}
                            />
                        </div>
                        <HorizontalStack gap={'200'}>
                            {tagMarkup}
                        </HorizontalStack>
                    </VerticalStack>
                </Box>
            </LegacyCard>
            <LegacyCard>
                <Box padding={400}>
                    <div>
                        <Text as="h3" variant="headingMd">Rewarding criteria</Text>
                        <Text as='p' color='subdued'>Specify parameters for  issuing, and calculating rewards</Text>
                    </div>
                </Box>
                <Divider />
                <Box padding={400}>
                    <VerticalStack gap={200}>
                        {/* hidden only for phase 1 we'll enable it in phase 2 */}
                        {/* <div>
                                <Text as="h3" variant='headingSm' >Order-channels to reward customers</Text>
                                <LegacyStack alignment='center' spacing='tight'>
                                    <RadioButton
                                        label="Online & POS"
                                        id="online_pos"
                                        name="rewardable_sales_channels"
                                        value="online_and_pos"
                                        checked={formik.values.rewardable_sales_channels === 'online_and_pos'}
                                        onChange={() => formik.setFieldValue('rewardable_sales_channels', 'online_and_pos')}
                                    />
                                    <RadioButton
                                        label="Online only"
                                        id="online"
                                        name="rewardable_sales_channels"
                                        value="online"
                                        checked={formik.values.rewardable_sales_channels === 'online'}
                                        onChange={() => formik.setFieldValue('rewardable_sales_channels', 'online')}
                                    />
                                </LegacyStack>
                            </div>
                            <Divider /> */}
                        <Text as="h3" variant='headingSm'>Order settings</Text>
                        <div>
                            <Text as="h3" >Reward by order status</Text>
                            <LegacyStack alignment='center' spacing='tight'>
                                <RadioButton
                                    label="Paid"
                                    id="order_status_paid"
                                    name="award_points_when_order_marked_as"
                                    value="Paid"
                                    checked={formik.values.award_points_when_order_marked_as === 'Paid'}
                                    onChange={() => formik.setFieldValue('award_points_when_order_marked_as', 'Paid')}
                                />
                                <RadioButton
                                    label="Fulfilled"
                                    id="order_status_fulfilled"
                                    name="award_points_when_order_marked_as"
                                    value="Fulfilled"
                                    checked={formik.values.award_points_when_order_marked_as === 'Fulfilled'}
                                    onChange={() => formik.setFieldValue('award_points_when_order_marked_as', 'Fulfilled')}
                                />
                            </LegacyStack>
                        </div>
                        <div>
                            <Text as="h3">Rewardable order elements</Text>
                            <Text as='p' color='subdued'>Set how order-total is calculated for points earning (on top of subtotal).</Text>
                        </div>
                        <LegacyStack alignment='center' spacing='tight'>
                            <SingleCheckbox
                                checked={formik.values.order_total_reward_value_include_discounts}
                                handleChange={(name, value) => formik.setFieldValue(name, value ? 1 : 0)}
                                label='Includes discount'
                                name='order_total_reward_value_include_discounts'
                            />
                            <SingleCheckbox
                                checked={formik.values.order_total_reward_value_include_taxes}
                                handleChange={(name, value) => formik.setFieldValue(name, value ? 1 : 0)}
                                label='Includes tax'
                                name='order_total_reward_value_include_taxes'
                            />
                            <SingleCheckbox
                                checked={formik.values.order_total_reward_value_include_shipping}
                                handleChange={(name, value) => formik.setFieldValue(name, value ? 1 : 0)}
                                label='Includes shipping'
                                name='order_total_reward_value_include_shipping'
                            />
                        </LegacyStack>
                        <div>
                            <Text color='subdued'>Let say Subtotal: {currency}100.00, Discount: {currency}10.00, Tax: {currency}5.00, Shipping: {currency}12.00</Text>
                            <Text color='subdued'>Then, <Text as='span' fontWeight='bold'>Rewardable total = {currency}{rewardableTotal}</Text></Text>
                        </div>
                    </VerticalStack>
                </Box>
            </LegacyCard>

            <LegacyCard>
                {/* <div style={{ opacity: data?.store_credit_scope === 1  ? '1' : '0.5', pointerEvents: data?.store_credit_scope === 1 ? '' : 'none' }}> */}
                <Box padding={400}>
                    <HorizontalStack align='space-between' blockAlign='center'>
                        <div>
                            <HorizontalStack gap={100} blockAlign='center'>
                                <Text as="h3" variant="headingMd">Conversion to/from store-credit</Text>
                                <span className='status-badge'><Badge status={formik.values.store_credit_status === 1 ? 'success' : ''}>{formik.values.store_credit_status === 1 ? 'On' : 'Off'}</Badge></span>
                            </HorizontalStack>
                            <Text as='p' color='subdued'>Convert accrued points-balance into store-credit & vice-versa.</Text>
                        </div>
                        <div className='lnr-point-actionlist' style={{ opacity: data?.store_credit_scope === 1 ? '1' : '0.5', pointerEvents: data?.store_credit_scope === 1 ? '' : 'none' }} >
                            <Button id='status' onClick={() => formik.setFieldValue('store_credit_status', formik.values.store_credit_status === 1 ? 0 : 1)}>{formik.values.store_credit_status === 1 ? 'Turn off' : 'Turn On'}</Button>
                        </div>
                    </HorizontalStack>
                </Box>
                <Divider />
                
                <Box padding={400}>
                    <VerticalStack gap={200}>
                        {data?.store_credit_scope !== 1 ?
                            <VerticalStack gap={400}>
                                <Banner
                                    status="warning"
                                    title={<><HorizontalStack gap={100}>
                                        <Text as="h2" variant="headingMd">Store credit access</Text>
                                    </HorizontalStack>
                                    <Text as="h4" >{'Require: Admin (Store-owner) must authorize Store-credit access and turn it on in Shopify Admin -> Settings.'}</Text></>}
                                    action={{ content: 'Grant access', onAction: () => updateScopeStoreCredit() }}
                                >
                                </Banner>
                            </VerticalStack>
                            : ''
                        }
                        <div style={{ opacity: data?.store_credit_scope === 1 ? '1' : '0.5', pointerEvents: data?.store_credit_scope === 1 ? '' : 'none' }}>
                            <VerticalStack gap={200}>
                                <SingleCheckbox
                                    checked={formik.values.store_credit_min_bal_status}
                                    handleChange={(name, value) => formik.setFieldValue(name, value ? 1 : 0)}
                                    label='Set minimum points-balance required for store-credit conversion'
                                    name='store_credit_min_bal_status'
                                />
                                {formik.values.store_credit_min_bal_status ? <Input
                                    value={removeLeadingZerosRegex(Number(formik.values.store_credit_min_bal))}
                                    type="number"
                                    min={1}
                                    max={100000}
                                    maxLength={6}
                                    placeholder={'e.g. 100'}
                                    name='store_credit_min_bal'
                                    suffix={'Points'}
                                    handleChange={(name, value) => (String(value)?.length <= 6 ? formik.setFieldValue(name, Number(value) >= 1 ? Number(value) : 0) : '')}
                                    handleBlur={formik.setFieldTouched}
                                    touched={formik.touched.store_credit_min_bal}
                                    error={formik.errors?.store_credit_min_bal}
                                    helper={formik.values.store_credit_min_bal ? `Points must at least reach ${formik.values.store_credit_min_bal} to allow store-credit conversion.` : null}
                                /> : ''
                                }
                                <HorizontalGrid columns={2} gap={4} alignItems='start'>
                                    <div style={{ pointerEvents: 'none' }}>
                                        <div className="Polaris-Labelled__LabelWrapper">
                                            <div className="Polaris-Label">
                                                <label className="Polaris-Label__Text">Primary-market currency</label>
                                            </div>
                                        </div>
                                        <div style={{ opacity: '0.7' }}>
                                            <Input
                                                value={formik.values.store_credit_default_currency}
                                                name='store_credit_default_currency'
                                            // disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <Input
                                        label="Set conversion-transaction value"
                                        value={removeLeadingZerosRegex(formik.values.store_credit_default_currency_conversion_value)}
                                        type="number"
                                        min={1}
                                        max={100000}
                                        name='store_credit_default_currency_conversion_value'
                                        placeholder={'e.g. 100'}
                                        suffix={'Pts = 1 Store-credit'}
                                        handleChange={(name, value) => (String(value)?.length <= 6 ? formik.setFieldValue(name, Number(value) >= 1 ? Number(value) : 0) : '')}
                                        handleBlur={formik.setFieldTouched}
                                        touched={formik.touched.store_credit_default_currency_conversion_value || (!formik.values.store_credit_default_currency_conversion_value && data?.store_credit_scope === 1)}
                                        error={formik.errors?.store_credit_default_currency_conversion_value || (!formik.values.store_credit_default_currency_conversion_value && data?.store_credit_scope === 1 ? 'Enter a non-zero whole value as point' : '')}
                                    />
                                </HorizontalGrid>
                                {data?.add_more_currency_status ? <HorizontalStack align='end' blockAlign='center'>
                                    <div className='lnr-point-actionlist'>
                                        <Button fullWidth icon={PlusMinor} onClick={() => handlePopUpModal(true, 'store_credit_popup')}>Add more currency</Button>
                                    </div>
                                </HorizontalStack> : ''}
                                {storeCreditDetail?.length ? (
                                    <>
                                        <div className='currency-table-container'>
                                            <IndexTable
                                                itemCount={loading === 'dataload' ? 0 : storeCreditDetail.length}
                                                emptyState={loading === 'dataload' ? <div className='spinner'><Spinner /></div> : emptyStateMarkup}
                                                selectable={false}
                                                headings={[
                                                    { title: '' },
                                                    { title: '' },
                                                    { title: '' }
                                                ]}
                                            >
                                                <>
                                                    {storeCreditDetail.map((item, index) => (
                                                        <>
                                                            <RowMarkup item={item} index={index} />
                                                        </>))}
                                                </>

                                            </IndexTable>
                                        </div>
                                        {totalEnabledCount > 1 && (
                                            <div className='paginationContent'>
                                                <Pagination
                                                    previousTooltip="Previous"
                                                    hasPrevious={page > 1}
                                                    onPrevious={() => getStoreCreditList(page - 1)}
                                                    nextTooltip='Next'
                                                    hasNext={page < totalEnabledCount}
                                                    onNext={() => getStoreCreditList(page + 1)}
                                                />
                                            </div>
                                        )}

                                    </>
                                ) : null}
                                {disabledStoreCreditDetail?.length ? (<>
                                    <HorizontalStack blockAlign='end' gap='1'>
                                        <Text as='h2' variant='headingMd'>Market-disabled currency</Text>
                                        <Tooltip content='Regain access to market-disabled currencies by reactivating the Market/Currency in Shopify Settings -> Markets.' width='wide'><Icon source={CircleInformationMajor} /></Tooltip>
                                    </HorizontalStack>
                                    <div className='currencyDisabledMarket'>
                                        <IndexTable
                                            itemCount={loading === 'dataload' ? 0 : data?.disabled?.length}
                                            emptyState={loading === 'dataload' ? <div className='spinner'><Spinner /></div> : emptyStateMarkup}
                                            selectable={false}
                                            headings={[
                                                { title: '' },
                                                { title: '' },
                                                { title: '' }
                                            ]}
                                        >
                                            {disabledStoreCreditDetail.map((item, index) => (
                                                <IndexTable.Row
                                                    id={item.id}
                                                    key={index}
                                                >
                                                    <IndexTable.Cell>
                                                        <HorizontalStack gap={'2'}>
                                                            <Text color='subdued'>{item.label}</Text>
                                                            <Badge status='info'>Market-disabled</Badge>
                                                        </HorizontalStack>
                                                    </IndexTable.Cell>
                                                    <IndexTable.Cell>
                                                        <Text color='subdued'>{item.conversion_value} Pts = 1 Store-credit</Text>
                                                    </IndexTable.Cell>
                                                    <IndexTable.Cell>
                                                        <div className='flex-end'>
                                                            <ButtonGroup segmented >
                                                                <Button size='micro' disabled>
                                                                    <Tooltip content={'Disable'} >
                                                                        <Icon source={EditMajor} />
                                                                    </Tooltip>
                                                                </Button>
                                                                <Button size='micro' disabled>
                                                                    <Tooltip content={'Disable'} >
                                                                        <Icon source={HideMinor} />
                                                                    </Tooltip>
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </IndexTable.Cell>
                                                </IndexTable.Row>
                                            ))}
                                        </IndexTable>
                                    </div>
                                    {totalDisabledCount > 1 && (
                                        <div className='paginationContent'>
                                            <Pagination
                                                previousTooltip="Previous"
                                                hasPrevious={Disabledpage > 1}
                                                onPrevious={() => getDisabledStoreCreditList(Disabledpage - 1)}
                                                nextTooltip='Next'
                                                hasNext={Disabledpage < totalDisabledCount}
                                                onNext={() => getDisabledStoreCreditList(Disabledpage + 1)}
                                            />
                                        </div>
                                    )}
                                </>) : null}
                            </VerticalStack>
                            <ActionModal
                                popUpActive={popUpActive === 'store_credit_popup'}
                                handlePreviewPopUp={handlePopUpModal}
                                popUpContent={AddMoreCurrencyPopUPContent}
                                popUpTitle={<VerticalStack gap={200}>
                                    <Text as='h1' variant='headingLg'>Add more currency!</Text>
                                    <Text color='subdued' as='p' variant='bodyMd'>Currency records can’t be deleted once added, but they can be edited and deactivated.</Text>
                                </VerticalStack>}
                                primaryAction={popupId === 'currency_status' ? {
                                    content: disabledAddBtn ? 'Done' : 'Add',
                                    disabled: disabledAddBtn,
                                    loading,
                                    onAction: () => {
                                        setSelectedOldCreditCurrency(selectedCreditCurrency);
                                        setDisabledAddBtn(true);
                                        handlePopupActivation(); 
                                    }
                                } : {
                                    content: 'Add currency-conversion',
                                    disabled: !selectedCreditCurrency.length || isDisableAddCurrencyBtn || selectedAddCurrencyList.filter((item) => !item.conversion_value || item.conversion_value === '')?.length,
                                    loading,
                                    onAction: () => handleAddCurrencyConversion()
                                }}
                                secondaryActions={[
                                    {
                                        content: 'Cancel',
                                        onAction: () => {
                                            if (popupId === 'currency_status') {
                                                handlePopupActivation();
                                                setSelectedCreditCurrency(selectedOldCreditCurrency);
                                            } else {
                                                handlePopUpModal();
                                            }
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </VerticalStack>
                </Box>
                <Divider />
                <Box padding={400}>
                    {data?.store_credit_scope !== 1 ? <div className='disabled-link'>
                        <Text>Required <Link>new customer-account/login-method</Link> enabled for store-credit to work.</Text>
                    </div> : <Text>Required <Link onClick={data?.store_credit_scope === 1 ? handleRedirectSetting : () => { }} url={`https://admin.shopify.com/store/${config_variable.shop_name.split('.')[0]}/settings/customer_accounts`}>new customer-account/login-method</Link> enabled for store-credit to work.</Text>}
                </Box>
            </LegacyCard>
            {/* <LegacyCard>
                        <Box padding={400}>
                            <Text as="h3" variant="headingMd">Account setting</Text>
                        </Box>
                        <Divider />
                        <Box padding={400}>
                            <VerticalStack gap={200}>
                                <Input
                                    label={<Text as="h3" variant='headingSm' >Preferred email for the account</Text>}
                                    value={formik.values.notification_email}
                                    type="text"
                                    name='notification_email'
                                    helper="Default to owner {email.id} if left blank. Test email, CSVs will be sent here"
                                    handleChange={formik.setFieldValue}
                                    handleBlur={formik.setFieldTouched}
                                    touched={formik.touched.notification_email}
                                    error={formik.errors?.notification_email}
                                />
                                <Divider />
                                <div>
                                    <Text as="h3" variant='headingSm' >Customer notifications</Text>
                                    <Text as="p" color='subdued'>This will be the email and name that your customers will see when they receive email notifications.</Text>
                                </div>
                                <HorizontalGrid columns={2} gap={10}>

                                    <Input
                                        label="Sender name"
                                        value={formik.values.notification_name}
                                        type="text"
                                        name='notification_name'
                                        handleChange={formik.setFieldValue}
                                        handleBlur={formik.setFieldTouched}
                                        touched={formik.touched.notification_name}
                                        error={formik.errors?.notification_name}
                                    />
                                    <Input
                                        label="Reply-to email"
                                        value={formik.values.notification_reply_email}
                                        type="text"
                                        name='notification_reply_email'
                                        handleChange={formik.setFieldValue}
                                        handleBlur={formik.setFieldTouched}
                                        touched={formik.touched.notification_reply_email}
                                        error={formik.errors?.notification_reply_email}
                                        helper={
                                            <span>
                                                yahoo.com and aol.com emails are{' '}<Link >not supported.</Link>
                                            </span>
                                        }
                                    />
                                </HorizontalGrid>
                            </VerticalStack>
                        </Box>
                    </LegacyCard> */}
            {active ? <Toast content={toastMessage} onDismiss={toggleActive} duration={2000} /> : null}
            <Footer link={' https://www.identixweb.com/helpdesk/loyalty-rewards/'} />
        </Frame>}
    </Page>
    );
}

export default Point;