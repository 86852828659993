import React, { useState } from 'react';
import { Box, Button, DropZone, InlineStack, Label, Text, Thumbnail } from '@shopify/polaris';
import { DeleteIcon, NoteIcon } from '@shopify/polaris-icons';
import { encodeImageFileAsURL } from '../helper/commonFunction';


function DropZoneFile({ fileAccept, fileActionTitle, fileActionHint, handleImportValue, fileLabel, fileType = 'file', fileValue, fileCustomIcon, customFileVal, handleicone, custome, redeempointcustome}) {
      

    const [customFile, setCustomFile] = useState();
    // const [custome, setcustome] = useState();
    // console.log(customFile);
    const uploadedFile = (customFileVal) && (
        <div className='Dropzone-border'>
            <Box padding={200} borderColor='border' borderRadius='150' borderWidth='1'>
                <InlineStack blockAlign='center' align='space-between'>
                    <InlineStack gap={200} blockAlign='center' >
                        <div className={`custom_icon ${fileLabel === 'Earn points icon' ? 'iwearnPoint' : fileLabel === 'Launcher icon' ? 'iwlauncherIcon' : 'iwredeemPoint'}`}>
                            <Thumbnail
                                size="small"
                                alt={customFileVal?.name || 'custom icon'}
                                source={
                                    customFileVal?.name ? window.URL.createObjectURL(customFileVal) : customFileVal?.includes('data:image/') ? customFileVal : `${customFileVal}` || NoteIcon
                                }
                            />
                        </div>
                        {fileLabel === 'Earn points icon' ? <Text>{customFile?.name || custome || (fileCustomIcon ? 'custom_icon.jpg' : fileValue)}</Text> : <Text>{customFile?.name || redeempointcustome || (fileCustomIcon ? 'custom_icon.jpg' : fileValue)}</Text>}
                        
                        <Button icon={DeleteIcon} variant='plain' onClick={() => { setCustomFile(''); handleImportValue(''); }}></Button>
                    </InlineStack>
                </InlineStack>
            </Box>
        </div>
    );

    const handleUpload = async (files) => {
        try {
            setCustomFile(files[0]);
            handleicone(files[0]);
            const getBase64 = await encodeImageFileAsURL(files[0]);
            handleImportValue(getBase64);

        } catch (error) {
            console.log('error----------------- ', error);
        }
    };

    return (
        customFileVal ? <><Label>{fileLabel}</Label><div>
            {uploadedFile}
        </div> </> : <Box paddingBlockStart={100}><DropZone label={fileLabel} allowMultiple={false} onDrop={handleUpload} type={fileType} accept={fileAccept}>
            <DropZone.FileUpload actionTitle={fileActionTitle} actionHint={fileActionHint} />
        </DropZone></Box>
    );
}

export default DropZoneFile;
