import '@shopify/polaris/build/esm/styles.css';
import './assets/css/style.css';
import React, { Suspense } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { DefaultLayout, routes } from './router/DefaultLayout';
import ErrorBoundary from './helper/Errorboundry';
// import { useSelector } from 'react-redux';
import { Spinner } from '@shopify/polaris';
import { Provider } from 'react-redux';
// import Store from './redux';
import store from './redux/store';

function App() {
    // const shop_info = useSelector((state) => state.data.store_data?.shop_data);
    // const onboardingstep = useSelector((state) => state.data.store_data?.shop_data?.onboarding_status);
    return (
        <Provider store={store}>
            <ErrorBoundary info={store}>
                <BrowserRouter>
                    <Suspense fallback={<div className="page_loader" key="loader" style={{ height: '451px' }}>
                        <Spinner size="large" /></div>}><Routes>
                            <Route path='/' element={<DefaultLayout />} >
                                {routes.map((route, i) => (
                                    <Route
                                        exact={true}
                                        path={route.path}
                                        key={i}
                                        element={route.element}
                                    />
                                ))}
                                <Route element={<Navigate to='/' replace />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </ErrorBoundary>
        </Provider>
    );
}

export default App;
