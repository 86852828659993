import { Button, Frame, Modal, BlockStack } from '@shopify/polaris';
import React from 'react';

function DeleteModal({
    deletePopUpActive,
    handleDeletePopUp,
    primaryAction,
    secondaryActions,
    popUpContent,
    popUpTitle
}) {
    return (
        <div>
            <Modal
                open={deletePopUpActive}
                onClose={() => handleDeletePopUp()}
                title={popUpTitle}
                primaryAction={primaryAction}
                secondaryActions={secondaryActions}
            >
                <Modal.Section>
                    <BlockStack>
                        <p>{popUpContent}</p>
                    </BlockStack>
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default DeleteModal;
