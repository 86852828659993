import React from 'react';
import { Select } from '@shopify/polaris';

const Selection = ({id, key, label, options, handleChange, selected, helper, name, placeholder, disabled}) => (
    <Select
        id={id}
        key={key}
        label={label}
        options={options}
        onChange={(value) => handleChange(name, value)}
        value={selected}
        helpText={helper}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
    />
);

export default Selection;
