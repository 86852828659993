import { Badge, Button, Frame, FullscreenBar, InlineStack, Card, Listbox, Popover, Text, Toast, Spinner } from '@shopify/polaris';
import { useFormik } from 'formik';
// import * as yup from 'yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ApiCall } from '../../helper/axios';
import CommonApiEndPoint from '../../helper/commonApiEndPoint';
import Input from '../../common-components/Input';
import { config_variable } from '../../helper/config';
import CommonRouter from '../../helper/commonRoute';
import { loyaltyAuthValidation } from '../../helper/commonFunction';
import Footer from '../../common-components/Footer';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import { setTitleBar } from '../../redux/slice/appBridgeSlice';
import { useDispatch } from 'react-redux';
import InputField from '../../common-components/InputField';

function CustomerBulkEdit() {
    const navigate = useNavigate();
    const { search } = useLocation();
    const [loading, setLoading] = useState('dataload');
    const [visible, setVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [active, setActive] = useState(false);
    // const [selectedDate, setSelectedDate] = useState(new Date());
    // const datePickerRef = useRef(null);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const [customerBulkData, setCustomerBulkData] = useState({ customers: [] });
    const dispatch = useDispatch();
    // const [{ month, year }, setDate] = useState({
    //     month: selectedDate.getMonth(),
    //     year: selectedDate.getFullYear()
    // });

    const formik = useFormik({
        initialValues: customerBulkData,
        enableReinitialize: true,
        // validationSchema: yup.object({
        //     customers: yup.array().of(
        //         yup.object({
        //             points: yup.number().required('Points must be a number').min(1, 'Points must be greater than 0')
        //         })
        //     )
        // }),
        onSubmit: (values) => {
            setLoading('submit');
            setTimeout(() => updateBulkCustomer(values), 500);
        }
    });

    useEffect(() => {
        dispatch(setTitleBar({}));
    }, [customerBulkData]);

    async function updateBulkCustomer(values) {
        try {
            const res = await ApiCall('POST', CommonApiEndPoint.customerBulkUpdate, values);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                setCustomerBulkData({
                    customers: [...resData]
                });
                setToastMessage(res?.data?.message);
                toggleActive();
                setLoading(false);
                // setTimeout(() => {
                //     navigate(CommonRouter.customers, { state: { config: config_variable.config, shop_name: config_variable.shop_name } });
                // }, 500);
            } else {
                setToastMessage(res?.data?.message);
                toggleActive();
                setLoading(false);
            }
        } catch (error) {
            console.log('error-----------------', error);
        }
    }
    async function getBulkCustomers() {
        try {
            const querySearchValue = loyaltyAuthValidation(config_variable.ACTION_DECRYPT, search.replace('?', ''));
            const customerIds = querySearchValue?.customerIds;
            const data = {
                ids: customerIds
            };
            const res = await ApiCall('POST', CommonApiEndPoint.customerBulkFetch, data);
            if (res?.data?.status === 'SUCCESS') {
                const resData = res?.data?.data;
                setCustomerBulkData({
                    customers: [...resData]
                });
            }
            setTimeout(() => setLoading(false), 500);
        } catch (error) {
            console.log('error-------------------', error);
        }
    }

    useEffect(() => {
        try {
            setLoading('dataload');
            getBulkCustomers();
        } catch (error) {
            console.log('error--1---------------', error);
        }
    }, []);

    function handleOnClose() {
        setVisible(false);
    }

    // function handleMonthChange(month, year) {
    //     setDate({ month, year });
    // }
    // function handleDateSelection(name, newSelectedDate) {
    //     const formattedDate = newSelectedDate.toISOString().slice(0, 10);
    //     formik.setFieldValue(name, formattedDate);
    //     setSelectedDate(newSelectedDate);
    //     setVisible(false);
    // }
    // useEffect(() => {
    //     if (selectedDate) {
    //         setDate({
    //             month: selectedDate.getMonth(),
    //             year: selectedDate.getFullYear()
    //         });
    //     }
    // }, [selectedDate]);

    const handleBulkPoint = (name, val, index, type) => {
        if (type === 'blur') {
            if (val < 1) {
                formik.setFieldValue(name, 1);
            }
        } else {
            formik.setFieldValue(name, val);
        }
    };
    const handleDiscloser = (name, value) => {
        formik.setFieldValue(name, value);
        setVisible(false);
    };

    return (
        <Frame logo={{
            width: 86,
            contextualSaveBarSource: 'https://cdn.shopify.com/s/files/1/2376/3301/files/Shopify_Secondary_Inverted.png'
        }}>
            {/* {process.env?.MODE !== 'local' && 
            // <Provider config={config_variable.config}>
            //     <TitleBar
            //         title=""
            //     />
            // </Provider>
            } */}
            <div>
                <FullscreenBar onAction={() => navigate(CommonRouter.customers, { replace: true })}>
                    <div
                        style={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: '1rem',
                            paddingRight: '1rem'
                        }}
                    >
                        <div style={{ marginLeft: '1rem', flexGrow: 1 }}>
                            <Text variant="headingLg" as="p">
                                {`Editing ${customerBulkData?.customers?.length} customers`}
                            </Text>
                        </div>
                        <div>
                            <Button primary onClick={formik.handleSubmit} disabled={!formik.dirty} loading={loading === 'submit'}>
                                Save
                            </Button>
                        </div>
                    </div>
                </FullscreenBar>
                {loading === 'dataload' ? <Card roundedAbove=''><div className='spinner'><Spinner /></div></Card> : <InlineStack>
                    <Card roundedAbove='' padding={0}>
                        <div className='bulk_data_table'>
                            <table>
                                <tr>
                                    <th>Email</th>
                                    <th>First name</th>
                                    <th>Last name</th>
                                    {/* <th>Date of Birth</th> */}
                                    <th>Point Balance</th>
                                    <th>Program Participation</th>
                                </tr>
                                {customerBulkData?.customers?.length && formik.values.customers?.map((item, index) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <Input
                                                    value={formik.values.customers?.[index]?.email}
                                                    name={`customers[${index}].email`}
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    disabled={true}
                                                    variant={'borderless'}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    value={formik.values.customers?.[index]?.first_name}
                                                    name={`customers[${index}].first_name`}
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    disabled={true}
                                                    variant={'borderless'}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    value={formik.values.customers?.[index]?.last_name}
                                                    name={`customers[${index}].last_name`}
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    disabled={true}
                                                    variant={'borderless'}
                                                />
                                            </td>
                                            {/* <td>
                                            <div className='bday-picker'>
                                                <Popover
                                                    active={visible === `customers[${index}].birthdate`}
                                                    autofocusTarget='none'
                                                    preferredAlignment='left'
                                                    fullWidth
                                                    preferInputActivator={false}
                                                    preferredPosition='below'
                                                    preventCloseOnChildOverlayClick
                                                    onClose={handleOnClose}
                                                    activator={
                                                        <><Input
                                                            value={formik.values.customers?.[index]?.birthdate ? new Date(formik.values.customers?.[index]?.birthdate).toLocaleString('en-us', {month:'long', day:'numeric'}) : '-'}
                                                            name={`customers[${index}].birthdate`}
                                                            handleChange={formik.setFieldValue}
                                                            handleBlur={formik.setFieldTouched}
                                                            handleFocus={(name) => setVisible(visible === name ? false : name)}
                                                            borderless
                                                        /></>
                                                    }
                                                >
                                                    <div>
                                                        <Card ref={datePickerRef}>
                                                            <DatePicker
                                                                month={month}
                                                                year={year}
                                                                selected={formik.values.customers?.[index]?.birthdate ? new Date(formik.values.customers?.[index]?.birthdate) : null}
                                                                onMonthChange={handleMonthChange}
                                                                onChange={({ end: newSelectedDate }) => handleDateSelection(`customers[${index}].birthdate`, newSelectedDate)}
                                                            />
                                                        </Card>
                                                    </div>
                                                </Popover>
                                            </div>
                                        </td> */}
                                            <td>
                                                <InputField
                                                    value={formik.values.customers?.[index].points}
                                                    name={`customers[${index}].points`}
                                                    onChange={(name, val) => (String(val)?.length <= 5 ? handleBulkPoint(name, Number(val), index) : '')}
                                                    onBlur={(name) => handleBulkPoint(name, formik.values.customers?.[index].points, index, 'blur')}
                                                    error={formik.errors?.customers?.[index]?.points}
                                                    touched={formik.touched?.customers?.[index]?.points}
                                                    variant=''
                                                    type='number'
                                                    min={1}
                                                    max={99999}
                                                />
                                            </td>
                                            <td>
                                                <Popover
                                                    active={visible === `customers[${index}].excluded`}
                                                    activator={<Button disclosure='down' variant='plain' onClick={() => setVisible(`customers[${index}].excluded`)}><Badge tone={formik.values.customers?.[index].excluded ? '' : 'success'}>{formik.values.customers?.[index].excluded ? 'excluded' : 'Include'}</Badge></Button>}
                                                    onClose={handleOnClose}
                                                >
                                                    <div className='custom-list'>
                                                        <Listbox onSelect={(value) => handleDiscloser(`customers[${index}].excluded`, value)}>
                                                            <Listbox.Option value={0} selected={formik.values.customers?.[index].excluded === 0}>
                                                                <Badge tone='success'>Include</Badge>
                                                            </Listbox.Option>
                                                            <Listbox.Option value={1} selected={formik.values.customers?.[index].excluded === 1}>
                                                                <Badge>Excluded</Badge>
                                                            </Listbox.Option>
                                                        </Listbox>
                                                    </div>
                                                </Popover>
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </table>
                        </div>
                    </Card>
                </InlineStack>}
                <Footer link={' https://www.identixweb.com/helpdesk/loyalty-rewards/'} />
                {active ? <Toast content={toastMessage} onDismiss={toggleActive} duration={1500} /> : null}
            </div>
        </Frame>
    );
}

export default CustomerBulkEdit;